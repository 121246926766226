import classNames from 'classnames'
import { ReactNode } from 'react'
import { MdClose } from 'react-icons/md'
import ReactModal from 'react-modal'

import { IconButton } from 'src/components/Buttons'
import { getAppRootElement } from 'src/utils'

interface ModalProps {
  isOpen: boolean
  onClose: () => void
  children: ReactNode
  theme?: 'gray' | 'white'
  className?: string
}

export const Modal = ({ isOpen, onClose, children, theme = 'gray', className = '' }: ModalProps) => (
  <ReactModal
    isOpen={isOpen}
    onRequestClose={onClose}
    parentSelector={getAppRootElement}
    ariaHideApp={false}
    // flex col so scrollbar appears when height is less than modal
    overlayClassName="fixed inset-0 bg-white-75 flex flex-col justify-center items-center"
    className={classNames(
      'shadow-xl relative max-w-full max-h-full m-6 px-16 py-3',
      {
        'bg-slate-200': theme === 'gray',
        'bg-white': theme === 'white',
      },
      className,
    )}
  >
    {/* extra wrapper div so scrollable area is padded and doesn't overflow screen because margin is not calculated with 100% */}
    <div className="px-4 max-w-full max-h-full overflow-auto">{children}</div>
  </ReactModal>
)

export const DialogModalContent = ({
  header = undefined,
  children = undefined,
  footer = undefined,
  onClose = undefined,
  className = '',
}: {
  header?: ReactNode
  children?: ReactNode
  footer?: ReactNode
  onClose?: () => void
  className?: string
}) => (
  <div className={className}>
    {header && <ModalHeader>{header}</ModalHeader>}
    {onClose && <ModalClose onClose={onClose} />}
    {children && <ModalBody>{children}</ModalBody>}
    {footer && <ModalFooter>{footer}</ModalFooter>}
  </div>
)

export const ModalHeader = ({ children, size = 'normal' }) => (
  <h2
    className={classNames('text-center pb-4 leading-tight', {
      'text-3xl font-semibold': size === 'normal',
      'font-semibold uppercase': size === 'small',
    })}
  >
    {children}
  </h2>
)

export const ModalClose = ({ onClose }) => (
  <IconButton className="absolute right-0 top-0 p-2 w-16 h-16" onClick={onClose} icon={MdClose} />
)

export const ModalFooter = ({ children }) => <div className="flex flex-row pt-4 justify-end space-x-2">{children}</div>

export const ModalBody = ({ className = '', children }) => <div className={className}>{children}</div>
